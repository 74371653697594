<template>
  <div class="subcribe-wrap">
    <List ref="myList" v-model:loading="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <div class="list-item" v-for="item in listData" :key="item.id">
        <div class="content-name" @click="handleClickDetail(item)">
          <div class="top-name">
            {{ item.search_content.filter.name }}-{{ formatContent(item.search_content.formulas) }}
          </div>
        </div>
        <div class="footer-wrap">
          <div class="time">{{ dayjs(item.created_at).format('YYYY-MM-DD HH:MM') }}</div>
          <div class="delete" @click="deleteHandle(item)">
            <Icon name="delete" size="18" color="#0C86FE" />
          </div>
        </div>
      </div>
    </List>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { List, Icon, Dialog } from 'vant'
import dayjs from 'dayjs'
import { getSubscribeList, deleteSubscribe } from '@/api/zhiku'

const router = useRouter()
const page = ref(0)
const total = ref(0)
const myList = ref(null)
const loading = ref(false)
const finished = ref(false)
const listData = ref([])

const onLoad = () => {
  page.value++
  getSubscribeList({
    page: page.value,
    page_size: 10,
    t: new Date().getTime()
  })
    .then(res => {
      listData.value.push(...res.data.map(item => ({ ...item, search_content: JSON.parse(item.search_content) })))
      console.log(listData.value)
      total.value = res.page.count
      loading.value = false
      if (listData.value.length >= total.value) {
        finished.value = true
      }
    })
    .catch(err => {
      console.log(err)
    })
}

const formatContent = content => {
  const arr = []
  Object.keys(content).forEach(key => {
    if (content[key] && content[key].length) {
      arr.push(content[key].join('-'))
    }
  })
  return arr.join('-')
}

const handleClickDetail = item => {
  router.push(
    `/zhiku/list?biomarkerId=${item.search_content.filter.id}&biomarkerName=${item.search_content.filter.name}&filterId=${JSON.stringify(item.search_content)}`
  )
}

const deleteHandle = item => {
  Dialog.confirm({
    title: '确认删除本条检索式？',
    confirmButtonColor: '#007aff'
  })
    .then(() => {
      deleteSubscribe(item.id).then(res => {
        if (res.code === 200) {
          page.value = 0
          total.value = 0
          listData.value = []
          finished.value = false
          myList.value && myList.value.check()
        }
      })
    })
    .catch(() => {
      // on cancel
    })
}
</script>

<style lang="less" scoped>
.subcribe-wrap {
  // background-color: #fff;

  .list-item {
    position: relative;
    padding: 20px 16px;
    background-color: #fff;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 16px;
      right: 16px;
      height: 1px;
      background-color: #ebedf0;
    }

    &:nth-of-type(1) {
      &::before {
        display: none;
      }
    }

    .content-name {
      display: flex;
      .top-name {
        padding: 3px 16px;
        background-color: #ebf5ff;
        border-radius: 15px;
        font-size: 14px;
      }
    }

    .footer-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 12px;

      .time {
        font-size: 12px;
        color: #999;
      }
    }
  }
}
</style>
